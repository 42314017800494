import React, { useRef, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider, jssPreset, makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import moment from "moment";
import "moment/locale/ar-tn";
import { create } from "jss";
import rtl from "jss-rtl";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import { mdiInformationOutline } from "@mdi/js";
import { mdiAlertCircle } from "@mdi/js";
import { mdiAlertCircleOutline } from "@mdi/js";
import { AuthProvider } from "./contexts/AuthContextProvider";
import { DataProvider } from "./contexts/DataContext";
import { PageProvider } from "./contexts/PageContext";
import { SebrProvider } from "./contexts/SebrContext";
import routes, { renderRoutes } from "./routes";
import useSettings from "./hooks/useSettings";
import { createAppTheme } from "./themes/index";
import "./assets/styles/App.css";
import "./utils/i18n";
import StompClientService from "./services/ws/stompClientService";
import useUnload from "./hooks/useUnload";
import themes from "./themes.json";
import config from "./utils/settings";

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: "#2A3072 !important",
  },
  success: {
    backgroundColor: "#017374 !important",
  },
  error: {
    backgroundColor: "#951A5C !important",
  },
  warning: {
    backgroundColor: "#CC5545 !important",
  },
}));
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
  key: "muiltr",
});
const App = () => {
  const { settings } = useSettings();
  const classes = useStyles();
  useUnload();
  useEffect(() => {
    moment.locale(settings.language === "ar" ? "ar-tn" : settings.language);
  }, [settings.language]);

  const stompClientService = new StompClientService();

  const theme = createAppTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    client: themes[config.REACT_APP_CLIENT_ID] ?? themes.zinki,
  });
  const notistackRef = useRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // TODO Karama: Add an ErrorBoundary with a custom fallback to prevent navigating back on errors
  // <ErrorBoundary fallback={<div>Something went wrong</div>}> </ErrorBoundary>

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={settings.direction === "rtl" ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <SnackbarProvider
              ref={notistackRef}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              dense
              maxSnack={1}
              action={(key) => (
                <IconButton
                  id="iconButton-close-snackbar"
                  aria-label="close-snackbar"
                  onClick={onClickDismiss(key)}
                  className="snackbar-close-iconButton"
                >
                  <Icon path={mdiClose} size={1} />
                </IconButton>
              )}
              iconVariant={{
                success: (
                  <Icon
                    path={mdiCheck}
                    size={1}
                    className="snackbar-success-check-icon"
                  />
                ),
                error: <Icon path={mdiAlertCircleOutline} size={1} />,
                warning: <Icon path={mdiAlertCircle} size={1} />,
                info: <Icon path={mdiInformationOutline} size={1} />,
              }}
              classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
              }}
            >
              <AuthProvider>
                <DataProvider stompClientService={stompClientService}>
                  <PageProvider>
                    <SebrProvider>{renderRoutes(routes)}</SebrProvider>
                  </PageProvider>
                </DataProvider>
              </AuthProvider>
            </SnackbarProvider>
          </StylesProvider>
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
};

export default App;
