import axios from "axios";

import ENDPOINTS from "./endpoints";

const ImageApi = {
  async editRegionByImageId(docId, imageId, body) {
    try {
      const url = ENDPOINTS.EDIT_REGION(docId, imageId);
      const response = await axios.put(url, body);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default ImageApi;
